<template>
  <div
    v-if="loader"
    class="text-center mt-5"
  >
    <b-spinner
      type="grow"
      label="Loading..."
    />
  </div>
  <div v-else>

    <b-card>

      <b-row>
        <b-col md="12">
          <!-- Image -->
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Banner image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>

                <b-img
                  v-img
                  :src="preview"
                  height="200"
                  width="200"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <b-card-text class="my-50" />
                <div class="d-inline-block">
                  <b-form-file
                    multiple
                    accept=".jpg, .png, .gif"
                    placeholder="Choose image"
                    @change="onChange($event)"
                  />
                </div>
              </b-media-body>
            </b-media>
            <b-row>
              <b-col
                cols="12"
                class="mt-50"
              >
                <b-button
                  v-if="!loader"
                  variant="primary"
                  class="mr-1"
                  @click="addBanner()"
                >
                  Save
                </b-button>
                <b-button
                  v-if="loader"
                  variant="primary"
                  disabled
                  class="mr-1"
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                  Loading...
                </b-button>

              </b-col>
            </b-row>
          </div>
        </b-col>

      </b-row>

      <b-row>
        <b-col
          v-for="image in bannerForm"
          :key="image.id"
          cols="2"
        >
          <div class="d-flex mt-1">
            <span
              class="deleteImgIcon"
              @click="deleteImg(image.id)"
            >
              <feather-icon icon="XIcon" />
            </span>
            <b-img
              ref="previewEl"
              v-img
              :src="image.url"
              class="ml-1 my-1 productImg"
              height="120"
              width="auto"
              max-width="150px"
            />
          </div></b-col>
      </b-row></b-card></div>

</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  data() {
    return {
      loader: false,
      required,
      preview: null,
      url: 'https://stage.zerocash.co/storage/',

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()
    const image = ref('')

    const bannerForm = ref({
    })
    return {
      getValidationState,
      bannerForm,
      setFormData,
      formData,
      image,
    }
  },
  mounted() {
    this.viewBanners()
  },
  methods: {
    deleteImg(id) {
      const bannerDelete = {
        banner_id: id,
      }
      axios.post('admin/banners_delete', bannerDelete).then(res => {
        if (res.status === 200) {
          this.viewBanners()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        // this.formData = new FormData()
      }).finally(() => {
        this.loader = false
      })
    },
    addBanner() {
      if (this.image) {
        // eslint-disable-next-line no-restricted-syntax
        this.formData.append('file', this.image)
      }
      this.loader = true
      axios.post('admin/banners', this.formData).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.preview = null
          this.loader = false
          this.viewBanners()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Added Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    viewBanners() {
      axios.get('admin/banners').then(res => {
        if (res.status === 200) {
          this.bannerForm = res.data?.data.banners
        }
      })
    },

    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      // this.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }

        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        // eslint-disable-next-line prefer-destructuring
        reader.readAsDataURL(input.files[0])
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
